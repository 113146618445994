import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TOnboardEmployeeRequestSchema,
  TOnboardEmployeeResponseSchema,
} from "./onboardEmployee.schema";

export const onboardEmployee = (axios: IAxiosInstance) => {
  return async (body: TOnboardEmployeeRequestSchema) => {
    const response = await axios.noRetry.post<
      IApiResponse<TOnboardEmployeeResponseSchema>
    >(`/api/employee/onboard`, body);

    return response.data;
  };
};
