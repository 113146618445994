import { z } from "zod";

type TParams = Parameters<typeof z.string>[0] & {
  readonly invalid_pattern_error?: string;
};

export const zipCode = (customErrors: TParams = {}) => {
  const { invalid_pattern_error = "Invalid Zip Code", ...zStringParams } =
    customErrors;
  return z.string(zStringParams).regex(/^\d{5}$/, invalid_pattern_error);
};
