import { z } from "zod";
import { uuid } from "../../schema-validation/schemes";

export const TaskKey = {
  PROFILE_INFORMATION: "profile",
  USER_ACCOUNT: "userAccount",
  BANK_ACCOUNT: "bankAccount",
  W4: "w4",
  FORM_I9: "formI9",
  CUSTOM_PAPERWORK: "customPaperwork",
} as const;

export type TaskKey = keyof typeof TaskKey;
export type TaskKeyValue = (typeof TaskKey)[keyof typeof TaskKey];

export const taskStatus = {
  Pending: "pending",
  InProgress: "in-progress",
  Incomplete: "incomplete",
  Complete: "complete",
  Disabled: "disabled",
} as const;

export type TaskStatus = (typeof taskStatus)[keyof typeof taskStatus];

export const taskSchema = z.object({
  correlationId: z.string(),
  taskId: uuid(),
  taskDefinitionId: uuid(),
  taskDefinitionTemplateId: uuid(),
  productDefinitionId: uuid(),
  companyID: uuid(),
  status: z.nativeEnum(taskStatus).default(taskStatus.Pending),
  fields: z.object({}).default({}),
});

export type ZTask = z.infer<typeof taskSchema>;
