import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";

const URL = "/api/switchCheckToPending";
export const switchCheckToPending = (axios: IAxiosInstance) => {
  return async (companyID: string, employeeCheckID: string) => {
    const response = await axios.noRetry.patch(URL, {
      companyID,
      employeeCheckID,
    });
    const resData = response.data;

    if (resData.success !== true) {
      throw new Error("Something went wrong");
    }
    return resData.data;
  };
};
