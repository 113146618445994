import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TCreateBankAccountAndSignFormsRequestSchema,
  TCreateBankAccountAndSignFormsResponseSchema,
} from "./createBankAccountAndSignForms.schema";

export const createBankAccountAndSignForms = (axios: IAxiosInstance) => {
  return async ({
    account_type,
    bank_name,
    bank_account_number,
    bank_routing_number,
    companyID,
    employeeID,
    is_primary,
    isOnboarding,
    test,
  }: TCreateBankAccountAndSignFormsRequestSchema) => {
    const response = await axios.noRetry.post<
      IApiResponse<TCreateBankAccountAndSignFormsResponseSchema>
    >(`/api/forms/employee/${companyID}/sign/US-direct_deposit/${employeeID}`, {
      account_type,
      bank_name,
      bank_account_number,
      bank_routing_number,
      companyID,
      is_primary,
      isOnboarding,
      employeeID,
      test,
    });

    if (!response?.data?.success) {
      throw new Error("Something went wrong");
    }
    return response?.data?.data;
  };
};
