import { z } from "zod";
import { productDefinitionSchema } from "../../products/schemas/productDefinitionSchema";
import { uuid } from "../../schema-validation/schemes";
import { taskDefinitionTemplateSchema } from "./taskDefinitionTemplateSchema";

export const taskDefinitionSchema = z.object({
  taskDefinitionId: uuid(),
  productDefinitionId: uuid(),
  taskDefinitionTemplateId: uuid(),
  companyID: uuid(),
  enabled: z.boolean().default(false),
  default: z.boolean().default(false),
  categoryEnablements: z
    .map(z.enum(["userAccount", "bankAccount"]), z.boolean())
    .default(
      new Map([
        ["userAccount", true],
        ["bankAccount", true],
      ])
    ),
});

export const taskDefinitionTaskSchema = taskDefinitionSchema.transform(
  (data) => {
    const { enabled, ...d } = data;
    return {
      ...d,
      fields: {
        default: d.default,
        enabled,
      },
    };
  }
);

export const taskDefinitionRefsPopulatedSchema = taskDefinitionSchema.extend({
  taskDefinition: taskDefinitionTemplateSchema.pick({
    requirements: true,
  }),
  productDefinition: productDefinitionSchema.pick({
    product: true,
    description: true,
    productType: true,
  }),
  inProgressTasks: z.number().default(0),
  completeTasks: z.number().default(0),
});

export type ZTaskDefinition = z.infer<typeof taskDefinitionSchema>;
export type ZTaskDefinitionRefsPopulated = z.infer<
  typeof taskDefinitionRefsPopulatedSchema
>;
export type ZTaskDefinitionTask = z.infer<typeof taskDefinitionTaskSchema>;
