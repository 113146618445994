import { z } from "zod";
import { schemes } from "../schema-validation";

export const getPreviewChecksSchema = z.object({
  disbursement: z.object({ method: z.string() }),
  employeeID: schemes.mongoObjectId("valid employee ID is required"),
  companyID: z.string(),
  check_date: z.string(),
  shifts: z.any().optional(),
  deductions: z.array(z.any()).optional(),
  paystub_id: z.string(),
  hasWageViolations: z.boolean().optional(),
});

export type TGetPreviewChecksRequestSchema = z.infer<
  typeof getPreviewChecksSchema
>;
