import { z } from "zod";
import { Jurisdiction, schemes } from "@zeal/common";

export const postGenerateW4UpdateLinkRequestSchema = z.object({
  companyID: schemes.companyID().optional(),
  employeeID: schemes.mongoObjectId().optional(),
  jurisdiction: z.nativeEnum(Jurisdiction),
  send_to_worker: z.boolean().optional(),
});

export type TPostGenerateW4UpdateLinkRequest = z.infer<
  typeof postGenerateW4UpdateLinkRequestSchema
>;
