import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TTransferPaycardFundsRequestSchema,
  TTransferPaycardFundsResponseSchema,
} from "./transferPaycardFunds.schema";

export const transferPaycardFunds = (axios: IAxiosInstance) => {
  return async (data: TTransferPaycardFundsRequestSchema) => {
    const { pay_card_id, ...rest } = data;

    const response = await axios.noRetry.post<
      IApiResponse<TTransferPaycardFundsResponseSchema>
    >(`/api/paycards/${pay_card_id}/transfer`, rest);

    return response.data;
  };
};
