import { z } from "zod";

import { EGovernmentPhotoIDType } from "../../accounts/onboarding/types";
import { ZealErrorAlias } from "../../errors/ZealErrors";
import { Jurisdiction } from "../../jurisdictions/Jurisdiction";
import { EKYCStatusList } from "../../kyc/EKYCStatus";
import { schemes } from "../../schema-validation";
import { EEmploymentStatus } from "../../workers-shared/EEmploymentStatus";
import { ELaForgeWorkerEnum } from "../../workers-shared/ELaForgeWorkerEnum";
import { benefitsClassSchema } from "../EBenefitsClass";
import { EPaySchedule } from "../EPaySchedule";
import { ETermReason } from "../ETermReason";
import { employeeAddressSchema } from "./employeeAddress";
import { employeeDatesSchema } from "./employeeDates";
import { employeeWagesSchema } from "./employeeWages";

export const employeeSchema = z
  .object({
    _id: schemes.mongoObjectId(),
    companyID: schemes.uuid(),
    email: schemes.email(),
    external_id: schemes.externalID(ZealErrorAlias.INVALID_EXTERNAL_ID.message),
    first_name: schemes.formattedName(),
    middle_initial: z.string(),
    last_name: schemes.formattedName(),
    ssn: schemes.ssn(),
    title: schemes.nonEmptyString("Title must have a minimum of 1 character"),
    phone_number: schemes.phone(),
    benefits_class: benefitsClassSchema,
    default_pay_schedule: z.nativeEnum(EPaySchedule),
    doubletime_rate: z.number().min(0),
    is_943: z.boolean(),
    is_scheduleH: z.boolean(),
    metadata: schemes.metadata().nullable(),
    overtime_rate: z.number().min(0),
    workLocationID: schemes.mongoObjectId(
      "workLocationID",
      ZealErrorAlias.WORK_LOCATION_NOT_FOUND.message
    ),
    is_salary: z.boolean(),
    is_regular: z.boolean(),
    employment_status: z
      .nativeEnum(EEmploymentStatus)
      .default(EEmploymentStatus.LIVE),
    onboarded: z.boolean().default(false),
    onboardedAt: z.string().optional(),
    kyc_status: z.enum(EKYCStatusList).optional(),
    kyc_watchlists: z.array(z.string()),
    kyc_pending_review: z.boolean().optional(),
    createdAt: z.string(),
    updatedAt: z.string(),
    everify_er_shared_referral_date_confirmation_at: z.string(),
    task_definition_ids: z.array(schemes.uuid()),
    term_reason: z.nativeEnum(ETermReason).optional().nullable(),
    taxengine_workerID: schemes.mongoObjectId(),

    // CLEANUP -- can any of these be removed?
    paychex_workerID: z.string(),
    user_type: z
      .nativeEnum(ELaForgeWorkerEnum)
      .default(ELaForgeWorkerEnum.Employee),
    emailNotConfirmed: z.boolean(),
    salt: z.string(),
    hash: z.string(),
    organization: z.string(),
    autopilot_on: z.boolean().default(false),
    class_code: z.number(),
    workAddress: z.string(),
    smarty_streets_info: z.any(),
    tax_info: z.any().default({}),
    state_tax_info: z.any().default({}),
    deposit_one: z.any().default({}),
    deposit_two: z.any().default({}),
    hellosign_company_sigId: z.string(),
    hellosign_employee_sigId: z.string(),
    hellosign_sig_req_id: z.string(),
    complete_paperwork: z.boolean().default(false),
    veriff_id: z.string(),
    veriff_stage: z.string(),
    veriff_images: z.any(),
    doc_type: z.string(),
    governmentPhotoIDType: z.nativeEnum(EGovernmentPhotoIDType),
    ss_card: z.string(),
    payroll_results: z.any().default([]),
    pto: z.any().default([]),
    vac: z.any().default([]),
    stage: z.string(),
    ocw_payeeID: z.string(),
    odo_user_id: z.string(),
    onboard_docs_faxed: z.boolean().default(false),
    business_ein: z.string(),
    paperless_w2: z.boolean(),
    working_state: z.nativeEnum(Jurisdiction),
    paycard_enabled: z.boolean(),
  })
  .merge(employeeAddressSchema)
  .merge(employeeDatesSchema)
  .merge(employeeWagesSchema);

export const employeeBaseRequirementSchema = employeeSchema
  .pick({
    email: true,
  })
  .required();

export type Employee = z.infer<typeof employeeSchema>;
