import { TaskKeyValue, TaskStatus } from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";

export interface IGetWorkerTasksOptions {
  employeeID: string;
  companyID: string;
}

export interface IGetWorkerTasksResponse {
  companyID: string;
  employeeID: string;
  fields: {
    [K in TaskKeyValue]?: {
      [key: string]: boolean;
    };
  };
  status: {
    [K in TaskKeyValue]?: TaskStatus;
  };
}

export const getWorkerTasks = (axios: IAxiosInstance) => {
  return async (options: IGetWorkerTasksOptions) => {
    const { employeeID, companyID } = options;
    const response = await axios.noRetry.get<
      IApiResponse<IGetWorkerTasksResponse>
    >(`/company/${companyID}/employee/${employeeID}/tasks`);

    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
