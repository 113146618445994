import { ZealApiResponse } from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { TPostGenerateW4UpdateLinkRequest } from "./postGenerateW4UpdateLink.schema";

export const postGenerateW4UpdateLink = (axios: IAxiosInstance) => {
  return async (body: TPostGenerateW4UpdateLinkRequest) => {
    const response = await axios.noRetry.post<ZealApiResponse<string>>(
      "/api/postGenerateW4UpdateLink",
      body
    );

    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
