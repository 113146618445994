import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TCreateBankAccountRequestSchema,
  TCreateBankAccountResponseSchema,
} from "./createBankAccount.schema";

export const createBankAccount = (axios: IAxiosInstance) => {
  return async (data: TCreateBankAccountRequestSchema) => {
    const response = await axios.noRetry.post<
      IApiResponse<TCreateBankAccountResponseSchema>
    >(`/bankaccount`, data);

    if (!response?.data?.success) {
      throw new Error("Something went wrong");
    }

    return response?.data?.data;
  };
};
