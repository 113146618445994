import { z } from "zod";
import { jurisdiction } from "../../schema-validation/schemes/jurisdiction";

export const w4Schema = z
  .object({
    state: jurisdiction(),
    working_state: jurisdiction(),
    prevResidency: jurisdiction(),
    prevWorkState: jurisdiction(),
    "AL-signature": z.boolean(),
    "AR-signature": z.boolean(),
    "AZ-signature": z.boolean(),
    "CA-signature": z.boolean(),
    "CO-signature": z.boolean(),
    "CT-signature": z.boolean(),
    "DC-signature": z.boolean(),
    "DE-signature": z.boolean(),
    "GA-signature": z.boolean(),
    "HI-signature": z.boolean(),
    "IA-signature": z.boolean(),
    "ID-signature": z.boolean(),
    "IL-signature": z.boolean(),
    "IN-signature": z.boolean(),
    "KS-signature": z.boolean(),
    "KY-signature": z.boolean(),
    "LA-signature": z.boolean(),
    "MA-signature": z.boolean(),
    "MD-signature": z.boolean(),
    "ME-signature": z.boolean(),
    "MI-signature": z.boolean(),
    "MN-signature": z.boolean(),
    "MO-signature": z.boolean(),
    "MS-signature": z.boolean(),
    "MT-signature": z.boolean(),
    "NC-signature": z.boolean(),
    "ND-signature": z.boolean(),
    "NE-signature": z.boolean(),
    "NJ-signature": z.boolean(),
    "NM-signature": z.boolean(),
    "NY-signature": z.boolean(),
    "OH-signature": z.boolean(),
    "OK-signature": z.boolean(),
    "OR-signature": z.boolean(),
    "PA-signature": z.boolean(),
    "RI-signature": z.boolean(),
    "SC-signature": z.boolean(),
    "US-signature": z.boolean(),
    "UT-signature": z.boolean(),
    "VA-signature": z.boolean(),
    "VT-signature": z.boolean(),
    "WI-signature": z.boolean(),
    "WV-signature": z.boolean(),
  })
  .partial();

export type PayrollEmployeeW4Payload = z.infer<typeof w4Schema>;
