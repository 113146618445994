import { z } from "zod";

import { companyTaskDefinitionEvent } from "./companyTaskDefinitionEvent";
import { contractorEvent } from "./contractorEvent";
import { employeeEvent } from "./employeeEvent";
import { transactionEvent } from "./transactionEvent";

export const eventsUnion = z.discriminatedUnion("source", [
  employeeEvent,
  contractorEvent,
  companyTaskDefinitionEvent,
  transactionEvent,
]);

export type EventsUnion = z.infer<typeof eventsUnion>;
