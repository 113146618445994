import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TGetWorkerInfoRequestSchema,
  TGetWorkerInfoResponseSchema,
} from "./getWorkerInfo.schema";

export const getWorkerInfo = (axios: IAxiosInstance) => {
  return async (query: TGetWorkerInfoRequestSchema) => {
    const response = await axios.noRetry.get<
      IApiResponse<TGetWorkerInfoResponseSchema>
    >(`/api/getWorkerInfo`, {
      params: query,
      withCredentials: true,
    });

    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
