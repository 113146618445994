import { zSchema } from "@zeal/common";

export const runSSNVerificationRequestSchema = zSchema.object({
  ssn: zSchema.string().length(9, "SSN must be 9 characters"),
  firstName: zSchema.string().min(1),
  lastName: zSchema.string().min(1),
  dateOfBirth: zSchema
    .string()
    .length(10, "Date of birth must be in the format YYYY-MM-DD"),
  zealId: zSchema.string(),
});

export type TRunSSNVerificationRequestSchema = zSchema.infer<
  typeof runSSNVerificationRequestSchema
>;

export const runSSNVerificationResponseSchema = zSchema.any();

export type TRunSSNVerificationResponseSchema = zSchema.infer<
  typeof runSSNVerificationResponseSchema
>;
