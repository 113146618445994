import { z } from "zod";

export const bankAccountNumber = () =>
  z
    .string()
    .regex(/^[a-zA-Z0-9]+$/, {
      message: "Account number must be alphanumeric",
    })
    .max(17, {
      message: "Account number must be 17 or fewer digits long",
    });
