export const COMPANY_GET_BALANCE =
  "/api/company/get-status/node-balance" as const;
export const TRANSACTION_EXTERNAL_GET =
  "/api/transaction/external/:external_id" as const;
export const TRANSACTION_EXTERNAL_GET_PARAM =
  "/api/transaction/external/" as const;
export const STATE_AGENCY_GET = "/api/state-agency/:odo_id" as const;
export const STATE_AGENCY_GET_PARAM = "/api/state-agency/" as const;
export const TRANSACTION_CREATE = "/api/transaction/create" as const;
export const STATE_AGENCY_TRANSACTION_BULK_GET =
  "/api/state-agency/transactions" as const;
export const COMPANY_ONBOARD = "/api/company/onboard" as const;
export const COMPANY_ADD_EIN = "/api/company/add-ein" as const;
export const COMPANY_RUN_KYB = "/api/company/kyb/:odo_user_id" as const;
export const STATE_AGENCY_ONBOARD = "/api/state-agency/onboard" as const;
export const COMPANY_ADD_ACH = "/api/company/add-ach" as const;
export const STATE_AGENCY_ADD_ACH = "/api/state-agency/add-ach" as const;
export const COMPANY_VERIFY_ACH = "/api/company/verify-ach" as const;
export const COMPANY_STATUS_GET =
  "/api/company/get-status/:company_id" as const;
export const EMPLOYEE_ONBOARD = "/api/employee/onboard" as const;
export const EMPLOYEE_ADD_ACH = "/api/employee/add-ach" as const;
export const EMPLOYEE_UPDATE_ACH = "/api/employee/update-ach" as const;
export const EMPLOYEE_ADD_KYC = "/api/employee/add-kyc" as const;
export const EMPLOYEE_STATUS_GET =
  "/api/employee/get-status/:employee_id" as const;
export const EMPLOYEE_PERMISSIONS = "/api/employee/get-permissions" as const;
export const BANK_VERIFY = "/api/bank/verify" as const;
export const GET_EMPLOYEE_BY_ODO_ID = "/api/employee/:odo_user_id" as const;
export const TRANSACTION_STATUS_LISTENER =
  "/api/transaction/status-listener" as const;
export const COMPANY_ADD_RESERVE = "/api/company/add-reserve" as const;
export const COMPANY_ADD_DEDUCTIONS = "/api/company/add-deductions" as const;
export const COMPANY_UPDATE_NODE = "/api/company/update-node" as const;
export const EMPLOYEE_UPDATE = "/api/employee/update" as const;
export const TRANSACTION_STATUS = "/api/transaction/status" as const;
export const GET_USER_DATA = "/api/paymentuser" as const;
export const RECONCILE_BANKS = "/api/reconcile-banks" as const;
export const TRANSACTION_UPDATE_STATUS =
  "/api/transaction/update-status" as const;
export const COMPANY_TRIGGER_MICRODEPOSIT =
  "/api/company/trigger-micro-deposit" as const;
export const RUN_KYC = "/api/kyc" as const;
export const RUN_ALL_KYC = "/api/allKyc" as const;
export const RUN_SSN_VERIFICATION = "/api/ssn-verification" as const;
export const UPDATE_USER_DATA = "/api/update-user" as const;
export const CUSTOMER_ACCOUNT_ADD_ACH =
  "/api/customer-accounts/:odoUserId/bank" as const;
export const CUSTOMER_ACCOUNT_ONBOARD = "/api/customer-accounts";
export const ARCHIVE_USER = "/api/archive";
