import { z } from "zod";
import { schemes } from "../schema-validation";

const ACTIONS = {
  CREATE: "create",
  UPDATE: "update",
} as const;

export const baseEventInput = z.object({
  source: z.string(),
  eventId: z.string().optional(),
  email: schemes.email(),
  userId: z.string(),
  userType: z.string(),
  authenticationMethod: z.string(),
  testMode: z.boolean(),
  correlationId: z.string(),
  environment: z.string(),
  payload: z.object({}),
  operation: z.enum([ACTIONS.CREATE, ACTIONS.UPDATE]),
  service: z.string(),
  collection: z.string(),
  timestamp: z.string(),
});

export const baseEventReturn = baseEventInput.transform((d) => ({
  ...d,
  modifier: {
    email: d.email,
    userId: d.userId,
    userType: d.userType,
    authenticationMethod: d.authenticationMethod,
  },
}));

export const customBaseEventReturn = <
  T extends z.ZodSchema<z.infer<typeof baseEventInput>>,
>(
  zodShape: T
) =>
  zodShape.transform((d) => ({
    ...d,
    modifier: {
      email: d.email,
      userId: d.userId,
      userType: d.userType,
      authenticationMethod: d.authenticationMethod,
    },
  }));
