import { RUN_SSN_VERIFICATION } from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import {
  TRunSSNVerificationRequestSchema,
  TRunSSNVerificationResponseSchema,
} from "./runSSNVerification.schema";

export const runSSNVerification = (axios: IAxiosInstance) => {
  return async (body: TRunSSNVerificationRequestSchema) => {
    const response =
      await axios.noRetry.post<TRunSSNVerificationResponseSchema>(
        RUN_SSN_VERIFICATION,
        body
      );

    return response.data;
  };
};
