import { TaskStatus } from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";

export interface IGetWorkerTasksOptions {
  employeeIDs: string[];
  companyID: string;
}

type WorkerTask = {
  employeeID: string;
  status: { [key: string]: TaskStatus };
};

export const getWorkerTasksStatus = (axios: IAxiosInstance) => {
  return async (options: IGetWorkerTasksOptions) => {
    const { employeeIDs, companyID } = options;
    const response = await axios.noRetry.post<IApiResponse<WorkerTask[]>>(
      `/company/${companyID}/employees/tasks/status`,
      {
        employeeIDs,
      }
    );
    return response.data;
  };
};
