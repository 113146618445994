import { z } from "zod";
import { Jurisdiction, schemes } from "@zeal/common";

export const postSendW4UpdateLinkRequestSchema = z.object({
  companyID: schemes.companyID().optional(),
  employeeID: schemes.mongoObjectId().optional(),
  jurisdiction: z.nativeEnum(Jurisdiction),
});

export type TPostSendW4UpdateLinkRequest = z.infer<
  typeof postSendW4UpdateLinkRequestSchema
>;
