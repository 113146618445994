/**
 * Actions that can be performed by a user on a resource.
 *
 * @description
 * We are using bitmap flags to make it fast and efficient to determine if a user has the required permissions.
 */
export const EPermissionActions = {
  Create: 1,
  Read: 2,
  Update: 4,
  Delete: 8,
  // Next item would be 16
} as const;

export const AllActions =
  EPermissionActions.Create |
  EPermissionActions.Read |
  EPermissionActions.Update |
  EPermissionActions.Delete;

export type EPermissionActions =
  (typeof EPermissionActions)[keyof typeof EPermissionActions];
