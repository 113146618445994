import dayjs = require("dayjs");
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { z } from "zod";

import { schemes } from "../../schema-validation";

type NullableDate = string | null;

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("America/Los_Angeles");

const isValid = (date?: NullableDate) =>
  date != null ? dayjs(date).isValid() : true;

const dateFormat = (date?: NullableDate) =>
  // all dates are translated to PST timezone
  date != null ? dayjs(date).format("YYYY-MM-DD") : date;

export const employeeDatesSchema = z
  .object({
    term_date: z
      .string()
      .nullable()
      .refine(isValid, "Term Date is not a valid date")
      .transform(dateFormat),
    salary_firstDate: z
      .string()
      .nullable()
      .refine(isValid, "Salary first date is not a valid date")
      .transform(dateFormat),
    start_date: z
      .string()
      .refine(isValid, "Start date is not a valid date")
      .transform(dateFormat),
    work_week_start: z
      .string()
      .nullable()
      .refine(isValid, "Work week start is not a valid date"),
    dob: schemes.dob(),
  })
  .partial();

export type EmployeeDates = z.output<typeof employeeDatesSchema>;
